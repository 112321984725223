* {
  padding: 0;
  margin: 0;
}
.logo {
  text-align: center;
  margin-top: -100px;
}
.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.icons {
  padding-right: 10px;
  background-color: transparent;
}
.buttons {
  padding: 7px;
  font-size: 20px;
  margin-top: 13px;
  /* text-decoration: underline; */
  color: rgb(0, 0, 0);
  background-color: transparent;
  background-repeat: no-repeat;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  font-weight: bold;
}
.h1 {
  color: rgb(0, 0, 0);
  font-style: italic;
}
.h2 {
  padding-top: 10px;
}
